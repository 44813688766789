import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { logEvent } from '../../middleware/logger';
import '../../styles/Report.css';
import logo from '../../img/systemLogo.png'; // Import your company logo
import backgroundImage from '../../img/hol7.jpg'; // Import your background image
import loadingGif from '../../img/giphy.gif'; // Replace with the path to your loading gif

const auth = {
  password: null,

  setPassword(newPassword) {
    this.password = newPassword;
  },

  getPassword() {
    return this.password;
  },
};

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Add a loading state

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true); // Show loading spinner

    try {
      const signInResponse = await axios.post('https://tns-security-api-uat.thenakedscientists.co.za/api/Authentication/sign-in', {
        username,
        password,
      });

      if (!signInResponse.data.isSuccessful || signInResponse.data.data.length === 0) {
        setError('Login failed: Invalid username or password.');
        setLoading(false); // Hide loading spinner
        return;
      }

      const { userId } = signInResponse.data.data[0];
      logEvent('login_attempt', userId);

      const userClaimsResponse = await axios.post('https://tns-security-api-uat.thenakedscientists.co.za/api/Security/get-user-claims', {
        userId,
      });

      if (!userClaimsResponse.data.isSuccessful || userClaimsResponse.data.data.length === 0) {
        setError('Access denied: Unable to retrieve user claims.');
        setLoading(false); // Hide loading spinner
        return;
      }

      const userClaims = userClaimsResponse.data.data;
      const hasAccess = userClaims.some(claim => claim.Role === 'Finance' || claim.Role === 'Admin');

      if (hasAccess) {
        auth.setPassword(password);
        localStorage.setItem('authToken', 'some-token'); // Save an auth token

        // Use replace to prevent back navigation to the login page
        navigate('/dashboard', { replace: true });
      } else {
        setError('Access denied: You do not have the required permissions.');
      }
    } catch (error) {
      if (error.response) {
        setError(`Login failed: ${error.response.data?.message || 'An unexpected error occurred.'}`);
      } else if (error.request) {
        setError('Login failed: No response from the server.');
      } else {
        setError('Login failed: An unexpected error occurred.');
      }
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  return (
    <section
      className="h-screen flex items-center justify-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh', // Ensure the image covers the entire viewport height
      }}
    >
      {loading && (
        <div className="loading-overlay">
          <img src={loadingGif} alt="Loading..." className="loading-gif" />
        </div>
      )}

      <div className="login">
        {/* Logo above the login word */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={logo} alt="Company Logo" className="company-logo" style={{ width: '300px', height: '150px' }} />
        </div>
        <form onSubmit={handleLogin}>
          <h2 className="text-center text-lg mb-6">Login</h2>

          <input
            type="email"
            placeholder="Email address"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="custom-input"
          />

          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="custom-input"
          />

          <button className="login-button" type="submit">
            {loading ? '...' : 'Login'}
          </button>

          {error && <div className="text-center text-red-500 mt-3">{error}</div>}
        </form>
      </div>
    </section>
  );
};

export default LoginPage;
