import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import axios from 'axios';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faTimes, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import logoImage2 from '../../img/logo2.png';
import logoImage1 from '../../img/systemLogo.png';
import logoBase64 from '../../img/imageData'; // 
import backgroundImage from '../../img/hol7.jpg';  // Import your background image here
import '../../styles/Report.css';
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import loadingGif from '../../img/giphy.gif'; // Replace with the path to your loading gif


function ConsumptionReport() {
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState('');
  const [selectedRoute, setSelectedRoute] = useState('');
  const [selectedStatementConcept, setSelectedStatementConcept] = useState('');
  const [transType, setTransType] = useState('');
  const [dateType, setDateType] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [tchStatus, setTchStatus] = useState('');
  const [rejectReason, setRejectReason] = useState('');
  const [selectStatement, setSelectStatement] = useState('');
  const [queryResult, setQueryResult] = useState([]);
  const [tchStatuses, setTchStatuses] = useState([]);
  const [statementConcepts, setStatementConcepts] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [rows, setRows] = useState([]);
  let idCounter = 0;
  const rowsWithIds = rows.map(row => ({ ...row, id: idCounter++ }));
  const [loading, setLoading] = useState(false); // Add a loading state
  const navigate = useNavigate(); // For logout navigation


  // Fetch data from API URL when component mounts
  useEffect(() => {
    axios.get('https://transit-api-qa.thenakedscientists.co.za/api/Report/consumption-report-filters')
      .then(response => {
        const { tchStatuses, statementConcepts, routes, merchants } = response.data;
        setTchStatuses(tchStatuses);
        setStatementConcepts(statementConcepts);
        setRoutes(routes);
        setMerchants(merchants);
      })
      .catch(error => {
        console.error('Error fetching dropdown data:', error);
      });
  }, []);

  // Columns for the DataGrid
  const columns = [
    { field: 'merchant', headerName: 'Merchant', width: 250 },
    { field: 'routeName', headerName: 'Route Name', width: 250 },
    { field: 'deviceNumber', headerName: 'Device Number', width: 250 },
    { field: 'vehicleRegistration', headerName: 'Vehicle Registration', width: 250 },
    { field: 'vehicleCode', headerName: 'Vehicle Code', width: 250 },
    { field: 'stationName', headerName: 'Station Name', width: 250 },
    { field: 'transactionGuid', headerName: 'Transaction Guid', width: 250 },
    { field: 'cardNumber', headerName: 'Card Number', width: 250 },
    { field: 'serialNumber', headerName: 'Serial Number', width: 250 },
    { field: 'receivableAmount', headerName: 'Receivable Amount', width: 250 },
    { field: 'amount', headerName: 'Amount', width: 250 },
    { field: 'cardBalance', headerName: 'Card Balance', width: 250 },
    { field: 'transactionDate', headerName: 'Transaction Date', width: 250 },
    { field: 'receivingDate', headerName: 'Receiving Date', width: 250 },
    { field: 'paymentType', headerName: 'Payment Type', width: 250 },
    { field: 'direction', headerName: 'Direction', width: 250 },
    { field: 'tchTransactionSubmissionDate', headerName: 'TCH Transaction Submission Date', width: 250 },
    { field: 'tchTransactionResponseDate', headerName: 'TCH Transaction Response Date', width: 250 },
    { field: 'tchInitialTransactionCode', headerName: 'TCH Initial Transaction Code', width: 250 },
    { field: 'tchGuid', headerName: 'TCH Guid', width: 250 },
    { field: 'tchStatus', headerName: 'TCH Status', width: 250 },
    { field: 'processingDate', headerName: 'Processing Date', width: 250 },
    { field: 'isProcessed', headerName: 'Is Processed', width: 250 },
    { field: 'tchGuaranteed', headerName: 'TCH Guaranteed', width: 250 },
    { field: 'tchStatusCode', headerName: 'TCH Status Code', width: 250 },
    { field: 'invoiceID', headerName: 'Invoice ID', width: 250 },
    { field: 'statementConcept', headerName: 'Statement Concept', width: 250 },
    { field: 'statementReference', headerName: 'Statement Reference', width: 250 },
    { field: 'rejectReason', headerName: 'Reject Reason', width: 250 },
  ];

  // Function to handle the form submission
  const handleQuery = () => {
    setLoading(true); // Show loading spinner

    const consumptionReportRequest = {
      merchantId: selectedMerchant,
      rejectReason: "",
      tchStatus: tchStatus.toString(),
      tchStatementConcept: selectedStatementConcept,
      transactionDateFrom: fromDate,
      transactionDateTo: toDate,
      routeId: selectedRoute,
    };

    axios.post('https://transit-api-qa.thenakedscientists.co.za/api/Report/consumption-report', consumptionReportRequest, {
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        setRows(response.data);
        setLoading(false); // Hide loading spinner
        console.log('Data fetched successfully:', response.data);
      })
      .catch(error => {
        setLoading(false); // Hide loading spinner in case of an error
        console.error('Error fetching DataGrid data:', error);
      });
  };

   // Logout function
   const handleLogout = () => {
    localStorage.removeItem('authToken'); // Clear user authentication data
    navigate('/login', { replace: true }); // Redirect to login and disable back navigation
  };

  // Function to handle form reset
  const handleReset = () => {
    setSelectedMerchant('');
    setSelectedRoute('');
    setSelectedStatementConcept('');
    setTransType('');
    setDateType('');
    setFromDate('');
    setToDate('');
    setTchStatus('');
    setRejectReason('');
    setSelectStatement('');
    setQueryResult([]);
  };

  // Function to handle export to Excel
  const handleExportToExcel = () => {
    // Generate the current date and time string in South African time
    const now = new Date();
    const options = {
      timeZone: 'Africa/Johannesburg',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
    
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    const [{ value: month }, , { value: day }, , { value: year }, , { value: hour }, , { value: minute }, , { value: second }] = formatter.formatToParts(now);
    
    const timestamp = `${year}-${month}-${day} ${hour}-${minute}-${second}`;
  
    // Create the worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Consumption Report');
  
    // Generate the filename with timestamp
    const filename = `ConsumptionReport_${timestamp}.xlsx`;
  
    // Write the workbook to a file
    XLSX.writeFile(workbook, filename);
  };

  // Sidebar toggle function
const toggleSidebar = () => {
  document.body.classList.toggle('toggle-sidebar');
};

  // Function to handle export to PDF
  const handleExportToPDF = () => {
    const doc = new jsPDF('landscape');
  
    // Base64 image of the logo provided by you
    doc.addImage(logoBase64, 'PNG', 220, 15, 70, 36); // Adjust the position and size as needed
    
    // Add title
    doc.setFontSize(18);
    doc.text('Consumption Report', 14, 22);
  
    // Add subtitle
    doc.setFontSize(12);
    doc.text(`Generated on: ${new Date().toLocaleString()}`, 14, 30);
  
    // Add table header
    const tableColumn = columns.map(col => col.headerName);
    const tableRows = rows.map(row => columns.map(col => row[col.field]));
  
    // Add table with autoTable
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 50, // Adjust startY to prevent overlap with the logo
      theme: 'striped',
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [44, 62, 80] }, // Customize header background color
      alternateRowStyles: { fillColor: [245, 245, 245] }, // Alternating row colors
    });
  
    // Add footer with page numbers
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(`Page ${i} of ${pageCount}`, doc.internal.pageSize.getWidth() - 30, doc.internal.pageSize.getHeight() - 10);
    }
  
    // Generate the current date and time string in South African time
    const now = new Date();
    const options = {
      timeZone: 'Africa/Johannesburg',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
  
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    const [{ value: month }, , { value: day }, , { value: year }, , { value: hour }, , { value: minute }, , { value: second }] = formatter.formatToParts(now);
  
    const timestamp = `${year}-${month}-${day} ${hour}-${minute}-${second}`;
  
    // Generate the filename with timestamp
    const filename = `ConsumptionReport_${timestamp}.pdf`;
  
    // Save the PDF with the generated filename
    doc.save(filename);
  };
  

  return (
<>
  {/* ======= Header ======= */}
  <header id="header" className="header fixed-top d-flex align-items-center">
    <div className="d-flex align-items-center justify-content-between w-100">
      {/* Sidebar toggle button and logo on the left side */}
      <div className="d-flex align-items-center">
        <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar}></i>
        
        {/* Logo next to the sidebar toggle button */}
        <a href=" " className="logo d-flex align-items-center">
          <img src={logoImage1} alt="Company Logo" className="logo-img" />
        </a>
      </div>

      <div className="header-right-content">
        {/* Add any additional content here */}
      </div>
    </div>
  </header>


      {/* End Header */}
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <a className="nav-link" href="dashboard#">
              <i className="bi bi-grid"></i>
              <span>Dashboard</span>
            </a>
          </li>
          <li className="nav-heading" style={{ fontWeight: "bold" }}>Reports</li>
          <li className="nav-item">
            <a className="nav-link" href="BusRevenueReport">
              <i className="bi bi-card-list"></i>
              <span>Bus Revenue Report</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="ConsumptionReport">
              <i className="bi bi-card-list"></i>
              <span>Consumption Report</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="MerchantRevenueReport">
              <i className="bi bi-card-list"></i>
              <span>Merchant Revenue Report</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="RouteRevenueReport">
              <i className="bi bi-card-list"></i>
              <span>Route Revenue Report</span>
            </a>
          </li>
          <li className="nav-heading" style={{ fontWeight: "bold" }}>Log Out</li>
          <li className="nav-item">
            <button className="nav-link" onClick={handleLogout}>
              <i className="bi bi-box-arrow-right"></i>
              <span>Log Out</span>
            </button>
          </li>
        </ul>
      </aside>

      {/* Main Content */}
      <main
      id="main"
      className="main"
      style={{
        backgroundImage: `url(${backgroundImage})`, // Use the imported image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        padding: '20px',
        position: 'relative', // Set relative positioning to main
        zIndex: 1, // Ensure content stays above the background
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.8)', // Add a slight transparent overlay behind content
          zIndex: -1, // Keep the overlay behind the content
        }}
      ></div>
        <div className="pagetitle">
          <h1>Consumption Report</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="dashboard#">Home</a></li>
            </ol>
          </nav>
          <div style={{ textAlign: 'center' }}>
            <img src={logoImage2} alt="Dashboard Logo" style={{ width: '320px', height: '130px' }} />
          </div>
        </div>

        <div className="header-spacing"></div>

        {/* Loading GIF */}
        {loading && (
          <div className="loading-overlay">
            <img src={loadingGif} alt="Loading..." className="loading-gif" />
          </div>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
              <Select
                id="merchants"
                value={selectedMerchant} // Set the selected value here based on your application logic
                onChange={(e) => setSelectedMerchant(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Select Merchant</MenuItem>
                {merchants.map((merchant, index) => ( // Ensure that merchants is an array
                  <MenuItem key={index} value={merchant.id}>{merchant.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
          <Select
              id="routes"
              value={selectedRoute}
              onChange={(e) => setSelectedRoute(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">Select Route Name</MenuItem>
              {routes.map(route => (
                <MenuItem key={route.id} value={route.id}>{route.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              id="dateType"
              label="Card Number"
              value={dateType}
              onChange={(e) => setDateType(e.target.value)}
              variant="outlined"
            />
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  id="fromDate"
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  fullWidth
                  variant="outlined"
                  label="From Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="toDate"
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  fullWidth
                  variant="outlined"
                  label="To Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Select
                id="tchStatuses"
                value={tchStatus}
                onChange={(e) => setTchStatus(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Bus Number</MenuItem>
                <MenuItem value="Status 1">Status 1</MenuItem>
                <MenuItem value="Status 2">Status 2</MenuItem>
                <MenuItem value="Status 3">Status 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
              <Select
                id="tchStatuses"
                value={tchStatus}
                onChange={(e) => setTchStatus(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Select TCH Status</MenuItem>
                <MenuItem value="ALL">ALL</MenuItem>
                {tchStatuses.map(status => (
                  <MenuItem key={status.key} value={status.key}>{status.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Select
                id="selectStatement"
                value={selectStatement}
                onChange={(e) => setSelectStatement(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Select Reject Reason</MenuItem>
                <MenuItem value="Statement 1">Statement 1</MenuItem>
                <MenuItem value="Statement 2">Statement 2</MenuItem>
                <MenuItem value="Statement 3">Statement 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
          <Select
              id="statementConcepts"
              value={selectedStatementConcept} // Assuming you have a state variable to store the selected statement concept
              onChange={(e) => setSelectedStatementConcept(e.target.value)} // Assuming you have a state setter function for the selected statement concept
              displayEmpty
            >
              <MenuItem value="">Statement Concept</MenuItem>
              {statementConcepts.map(concept => (
                <MenuItem key={concept.key} value={concept.key}>{concept.value}</MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
        </Grid>

        <div className="row" style={{ marginBottom: '20px', marginTop: '20px' }}>
          <div className="col">
            <Button variant="contained" onClick={handleQuery} className="query-button">
              <FontAwesomeIcon icon={faArrowRight} /> Query
            </Button>
            <Button variant="contained" color="secondary" onClick={handleReset} className="reset-button">
              <FontAwesomeIcon icon={faTimes} /> Reset
            </Button>
          </div>
          <div className="col-auto">
            <Button variant="contained" className="export-button"  color="success" onClick={handleExportToExcel}>
              <FontAwesomeIcon icon={faFileExcel} style={{marginRight: '5px' }} /> Export to Excel
            </Button>
          </div>
          <div className="col-auto">
            <Button variant="contained" className="export-button" color="error" onClick={handleExportToPDF} style={{ marginLeft: '10px' }}>
              <FontAwesomeIcon icon={faFilePdf} style={{marginRight: '5px' }} /> Export to PDF
            </Button>
          </div>
        </div>

        <div style={{ height: 350, width: '100%' }}>
          <DataGrid
            rows={rowsWithIds}
            columns={columns}
            pageSize={5}
            disableSelectionOnClick
            headerClassName="datagrid-header"
          />
        </div>
      </main>
      {/* End Main Content */}

      {/* Back to Top Button */}
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}

export default ConsumptionReport;
